// eslint-disable-next-line

import { createStyles, makeStyles } from '@material-ui/core';
import {
  Avatar, Card, FormControl, Grid, InputLabel, Select, Theme,
  Tooltip,
  TooltipProps,
  tooltipClasses
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { styled } from '@material-ui/styles';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BarLoader } from 'react-spinners';

import { useAuth } from '../../contexts/auth';
import callService from '../../services/callService';
import { phoneNumberNoDDI, toBrazilDate, toBrazilTime } from '../../components/mask';
import Call, { HistoryBy } from '../../models/interfaces/call';
import { convertStatusToBrazilian, StatusCallList } from '../../models/enums/statusCall';
import ModalComponent from './components/modalComponent';
import { onSnapshot } from '../../config/firebase';
import ifoodIcon from '../../assets/images/ifood.png';
import anotaaiIcon from '../../assets/images/anotaai-logo.png';
import exclamationIcon from '../../assets/images/exclamation.png';
import infoIcon from '../../assets/images/info.png';
import { FirestoreStatusDoc } from '../../models/enums/firestoreStatusDoc';

// https://dev.to/hadi/infinite-scroll-in-firebase-firestore-and-react-js-55g3
// https://medium.com/@650egor/firestore-reactive-pagination-db3afb0bf42e

export interface Row {
  id: string;
  clientName: string;
  clientAddress: string;
  driver: {
    id: string;
    photo: string;
    name: string;
    phone: string;
    category: number;
  }
  destinations: any[];
  status: string;
  createdAt: Date;
  statusHtml: {
    color: string;
    description: string;
  },
  salesChannel: any;
  changeType: string;
  driverSuggestion: any;
  statusHistory: any[];
  driversNotAllowed: any[] | null | undefined;
  drivers: string[] | null | undefined;
  stage: number;
}

interface OwnProps {
  id: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  selectRow: {
    cursor: 'pointer'
  }
}));

const hasForward = (pItem: any) => {
  const forward = pItem.statusHistory.filter((x: any) => x.status === 'forwarded');
  const currentForward = forward[forward.length - 1];
  return currentForward?.length;
};

const hasDriverAlert = (x: any) => x.driversNotAllowed?.length > 0;

const getInfo = (x: any) => {
  let text = '';
  x.driversNotAllowed?.forEach((row: any) => {
    text += `${row.name} - Filtro de pagamento\n`;
  });
  return text;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 11,
    border: '1px solid #dadde9'
  }
}));

const showCallDriverState = (pItem: any) => {
  const item = pItem;
  const canceleds = item.statusHistory.filter((x: any) => x.status === 'refused').length;
  const info = canceleds > 1 ? `${canceleds} entregadores recusaram` : '1 entregador recusou';
  if (item.status === 'canceled') {
    if (canceleds > 0) {
      item.driver = {
        category: null,
        id: null,
        name: info,
        phone: null,
        photo: null
      };
    }
  } else if (item.status === 'waiting') {
    const forward = item.statusHistory.filter((x: any) => x.status === 'forwarded');
    const currentForward = forward[forward.length - 1];
    if ((forward.length > 0 && currentForward.justification) || canceleds > 1) {
      item.driver = {
        category: null,
        id: null,
        name: forward.length > 0 ? currentForward.justification : '',
        phone: null,
        photo: null
      };
      return item.driver;
    }
  }
  return item.driver;
};

export const customRow = (item: any) => ({
  ...item,
  statusHtml: convertStatusToBrazilian(item.status),
  destinations: item.destinations,
  notifications: item.notifications?.map((x: any) => ({ ...x, createdAt: x.createdAt.toDate() })),
  tariff: item.tariff ?? item.destinations[0].tariff, // TODO: Fix when refactoring
  driver: showCallDriverState(item),
  salesChannel: item.salesChannel,
  statusHistory: _.sortBy(item.statusHistory?.map((x: any) => ({
    ...x,
    date: x.date.toDate(),
    statusHtml: convertStatusToBrazilian(x.status)
  }))).filter((x: any) => x.status !== 'refused').reverse()
});

export default function index(props: OwnProps) {
  const [rows, setRows] = React.useState<Row[]>([]);
  const current = useAuth();
  let { subsidiaryId } = current;
  if (props.id) subsidiaryId = props.id;

  const owner: HistoryBy = {
    id: current.user?.uid ?? '?',
    name: current.user?.name ?? '?',
    photoURL: current.user?.photoURL ?? '?'
  };

  const classes = useStyles();
  const [hasMore, setHasMore] = React.useState(true);
  const [lastKey, setLastKey] = React.useState<Date>();
  const [selectedItem, setSelectedItem] = React.useState<any | undefined>();
  const [statusFilter, setStatusFilter] = React.useState<string | undefined>(undefined);
  const [openedModal, setOpenedModal] = React.useState<boolean>(false);

  const [openModal, setOpenModal] = React.useState(false);
  const toggleModal = () => {
    setOpenedModal(false);
    setOpenModal(!openModal);
  };

  const handleOpenModal = (item: any) => {
    if (item) {
      setSelectedItem(item);
      toggleModal();
      setOpenedModal(true);
    }
  };

  // eslint-disable-next-line prefer-const
  let listCache: any[] = [];

  const handleStatusChange = (event: any) => {
    if (event.target.value) {
      setStatusFilter(event.target.value);
      callService.getWithFilters(subsidiaryId, event.target.value).then((res) => {
        setRows(res.map((item: any) => customRow(item)));
      });
    } else {
      setStatusFilter(undefined);
    }
    listCache = [];
  };

  const fetchMoreData = () => {
    if (hasMore) {
      callService.fetchNextData(lastKey, subsidiaryId, statusFilter, 10).then((res) => {
        if (res.lastKey === undefined) {
          setHasMore(false);
          return;
        }
        listCache = updateList(listCache, res.data);
        // listCache.map((item: any) => customRow(item) as Row)
        const newData = res.data.map((item: any) => {
          // eslint-disable-next-line no-param-reassign
          if (!item?.changeType) item.changeType = FirestoreStatusDoc.Added;
          return customRow(item);
        });
        const lastItemKey = listCache.length ? listCache[listCache.length - 1].createdAt : new Date();
        setLastKey(lastItemKey);
        setRows(rows.concat(newData));
      });
    }
  };

  const updateList = (calls: any[], changedCalls: any[]) => {
    changedCalls.forEach((changedCall: any) => {
      // eslint-disable-next-line no-shadow
      const index = calls.findIndex((row: any) => row.id === changedCall.id);
      if (index < 0) {
        // eslint-disable-next-line no-param-reassign
        if (!changedCall?.changeType) changedCall.changeType = FirestoreStatusDoc.Added;
        calls.push(changedCall);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (changedCall.changeType === FirestoreStatusDoc.Modified) {
          // eslint-disable-next-line no-param-reassign
          calls[index] = changedCall;
        } else if (changedCall.changeType === FirestoreStatusDoc.Removed) {
          calls.splice(index, 1);
        }
      }
    });
    const sorted = _.orderBy(calls, (x) => x.createdAt, 'desc');
    return sorted;
  };

  const loadList = () => {
    const limit = listCache.length ? 0 : 20;
    const queryCalls = callService.getAllRealTimeStream(subsidiaryId, statusFilter, limit);
    const unsubscribe = onSnapshot(queryCalls, (snapshot: any) => {
      let lastItemKey;
      const docChanges = snapshot.docChanges().map((change: any) => {
        const item = change.doc.data();
        item.id = change.doc.id;
        item.createdAt = item.createdAt.toDate();
        item.destinations = item.destinations.map((x: any) => ({
          ...x,
          receiverName: x.receiverName === null || x.receiverName === '' ? 'Nome não informado' : x.receiverName,
          receiverPhone: x.receiverPhone === '' ? null : x.receiverPhone
        }));
        item.changeType = change.type;
        return item;
      });
      if (!openedModal) {
        if (!listCache.length) listCache.push(...docChanges);
        listCache = updateList(listCache, docChanges);
        setRows(listCache.map((item: any) => customRow(item) as Row));
        // eslint-disable-next-line prefer-const
        lastItemKey = listCache.length ? listCache[listCache.length - 1]?.createdAt : docChanges[docChanges.length - 1]?.createdAt;
        setLastKey(lastItemKey);
      } else {
        listCache = [];
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const unsubscribe = loadList();
    return () => {
      unsubscribe();
    };
  }, [statusFilter, openedModal]);

  // useEffect(() => {
  //   const unsubscribe = onSnapshot(callService.getAllRealTimeStream(subsidiaryId, statusFilter, 20), (snap: any) => {
  //     const listatest: Row[] = rows;
  //     let lastItemKey;
  //     snap.docChanges().forEach((change: any) => {
  //       const item = change.doc.data();
  //       lastItemKey = item.createdAt;
  //       item.id = change.doc.id;
  //       item.createdAt = item.createdAt.toDate();
  //       const novaLinha = customRow(item) as Row;

  //       if (change.type === 'added') {
  //         console.log('New: ', novaLinha.clientName);
  //         listatest.push(novaLinha);
  //         setLastKey(lastItemKey);
  //       } else if (change.type === 'modified') {
  //         console.log('Modified...');
  //         const ii = _.findIndex(listatest, ((x) => x.id === change.doc.id));
  //         if (ii !== -1) {
  //           listatest[ii] = novaLinha;
  //           console.log('Modified index: ', novaLinha.clientName);
  //         }
  //       } else if (change.type === 'removed') {
  //         console.log('Removed: ', novaLinha.clientName);
  //       } else {
  //         console.log('oi');
  //       }
  //     });

  //     setRows(listatest);
  //   });

  //   return () => unsubscribe();
  // }, [statusFilter]);

  return (
    <Card className="card-box mb-4">
      <div className="card-header py-3">
        <div className="card-header--title font-size-lg">
          Entregas recentes
        </div>
      </div>

      <div className="card-body">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="filter-status">Status</InputLabel>
              <Select native value={statusFilter} onChange={handleStatusChange} label="Status" inputProps={{ id: 'filter-status' }}>
                <option aria-label="None" value={undefined} />
                {StatusCallList.map((item: any) => <option key={item.key} value={item.key}>{item.value}</option>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <ModalComponent open={openModal} toggle={toggleModal} data={selectedItem !== undefined ? selectedItem as Call : undefined} owner={owner} />

      <div className="table-responsive">
        <InfiniteScroll
          dataLength={rows.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={(
            rows.length > 10 && (
              <div className="d-flex align-items-center justify-content-center py-3">
                <BarLoader color="var(--primary)" loading />
              </div>
            )
          )}
          height={600}
        >
          <table className="table table-hover text-nowrap mb-0">
            <thead>
              <tr>
                <th className="bg-white text-center">Data</th>
                <th className="bg-white text-left">Inicio/Destinos</th>
                {/* eslint-disable-next-line */}
                <th className="bg-white">&nbsp;</th>
                <th className="bg-white">Nome</th>
                <th className="bg-white text-center">Status</th>
                <th className="bg-white text-center">Info.</th>
              </tr>
            </thead>
            <tbody>
              {rows?.length === 0 && <tr><td colSpan={6} className="text-center">Nenhuma informação encontrada</td></tr>}
              {_.orderBy(rows, (x) => x.createdAt, 'desc')?.map((x) => (
                <tr key={x.id} className={classes.selectRow} onClick={() => handleOpenModal(x)}>
                  <td className="text-center">
                    <div>
                      <div>{toBrazilDate(new Date(x.createdAt))}</div>
                      <span>{toBrazilTime(new Date(x.createdAt))}</span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div>
                        <div>{x.clientName}</div>
                        {x.destinations.map((d, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <span className="text-black-50 d-block" key={i}>
                            {_.truncate(d.address, { length: 100, separator: '...' })}
                          </span>
                        ))}
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    {x.salesChannel?.name === 'iFood' && (<img src={ifoodIcon} alt="Logo do iFood" width={40} height={22} />)}
                    {x.salesChannel?.name === 'anotaai' && (<img src={anotaaiIcon} alt="Logo Anota Ai" width={100} height={40} />)}
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      {x.driver && (
                        <>
                          <Avatar alt="" src={x.driver.photo} className="mr-2" />
                          <div>
                            <div>{x.driver.name}</div>
                            <span className="text-black-50 d-block">{phoneNumberNoDDI(x.driver.phone)}</span>
                          </div>
                        </>
                      )}
                      {x.driverSuggestion && x.status === 'waiting' && !hasForward(x) && !x.driver && (
                        <>
                          <div>
                            <div>💡Sugestão💡</div>
                            <span className="text-black-50 d-block">
                              {x.driverSuggestion?.name}
                            </span>
                            <span className="text-black-50 d-block">
                              {_.truncate('Em trãnsito para endereço próximo...', { length: 100, separator: '...' })}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                  <td className="text-center">
                    <div className={`h-auto py-0 px-3 badge badge-${x.statusHtml.color}`}>{x.statusHtml.description}</div>
                  </td>
                  <td className="text-center">
                    {hasDriverAlert(x) && (
                      <>
                        <div>
                          <HtmlTooltip
                            title={(
                              <div>
                                <Typography color="inherit">Restrições</Typography>
                                <p className="text-black-50 d-block mb-1"><em>Nome/Tipo de restrição</em></p>
                                <p className="text-black-50 d-block mb-1">{getInfo(x)}</p>
                              </div>
                            )}
                          >
                            <img src={exclamationIcon} alt="Warn" width={25} height={25} />
                          </HtmlTooltip>
                        </div>
                      </>
                    )}
                    {!hasDriverAlert(x) && x.status === 'waiting' && (
                      <>
                        <div>
                          <HtmlTooltip
                            title={(
                              <div>
                                <Typography color="inherit">Informações</Typography>
                                <p className="text-black-50 d-block mb-1">{`Chamada em estágio ${x.stage}.\n`}</p>
                                <p className="text-black-50 d-block mb-1 mt-1">{x.stage !== 4 && (`Total de ${x.drivers?.length} encontrado(s).`)}</p>
                                <p className="text-black-50 d-block mb-1 mt-1">{x.stage === 4 && ('Enviada para todos online.')}</p>
                              </div>
                            )}
                          >
                            <img src={infoIcon} alt="Info" width={25} height={25} />
                          </HtmlTooltip>
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </Card>
  );
}
